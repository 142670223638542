@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.big-text .custom-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

:root {
  --deepBlue: #004d9f;
  --lightGray: #e0e0e0;
}

.bg-deepBlue {
  background-color: #004d9f;
}

.text-white {
  color: #FFFFFF;
}

.big-text {
  font-size: 100rem;
}
